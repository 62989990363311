<template>
  <div>
    <LoadingVehicle v-if="saving" />
    <QForm v-else @submit="saveColourAndExtras">
      <h6 class="q-mt-none q-mb-lg text-grey-8">Accessories & Options</h6>
      <div class="row justify-center" v-if="$apollo.queries.vehicleOptions.loading">
        <QSpinner size="3em" color="grey-7" />
      </div>
      <div v-else>
        <FormError :show="$v.$error && $v.$dirty" class="q-my-md" />
        <div v-for="error in validationErrors" :key="error" class="q-my-md">
          <div class="text-red text-body3 q-mb-none error-text">
            <p>
              {{ error.message }}
            </p>
            <ul style="padding-left: 5px">
              <li v-for="detail in error.rules" :key="detail">
                {{ detail }}
              </li>
            </ul>
          </div>
        </div>
        <div class="row q-col-gutter-md">
          <div class="col-6">
            <p class="text-body1 text-weight-bold text-grey-8">{{ exteriorColorLabel }}</p>
          </div>
          <div class="col-6">
            <p class="text-body1 text-weight-bold text-grey-8">{{ interiorColorLabel }}</p>
            <InformationCaption
              v-if="allRequired[interiorColour]"
              class="q-mb-sm"
              caption="This has been automatically selected based on the exterior colour"
            />
          </div>
        </div>
        <div class="row q-col-gutter-md">
          <ColourAndExtrasExteriorColour
            class="col-6"
            :disabled="isDisabled"
            :colours="vehicleOptions.exteriorColours"
            :error="$v.exteriorColour.$error"
            :precluded="allPrecluded"
            v-model="$v.exteriorColour.$model"
          />
          <ColourAndExtrasInteriorColour
            class="col-6"
            :colours="vehicleOptions.interiorColours"
            :error="$v.interiorColour.$error"
            :precluded="allPrecluded"
            :disabled="!selectedExteriorColour.id || allRequired[interiorColour] || isDisabled"
            v-model="$v.interiorColour.$model"
          />
        </div>
        <QSeparator class="q-my-md" />
        <div class="row">
          <h6 class="col-12 q-mt-none q-mb-lg text-grey-8 text-weight-bold">Accessories</h6>
          <div class="col-12">
            <MilesColourAndExtrasAdditionalOptions
              searchFieldTitle="Description"
              inputFieldTitle="Dealer Price (excl GST)"
              searchFieldLabel="Accessory Name"
              inputFieldLabel="0.00"
              v-model="adhocEquipment"
              :options="milesAccessories"
              :onUpdateOptions="handleUpdateAccessories"
              filterByColumn="description"
              sortByColumn="description"
              :isLoading="$apollo.queries.options.loading"
              :isDisabled="isDisabled"
              :isItemisedPricingEnabled="pricingConfiguration.isItemisedPricingEnabled"
            />
          </div>
        </div>

        <QSeparator class="q-my-md" />
        <div class="row">
          <h6 class="col-12 q-mt-none q-mb-lg text-grey-8 text-weight-bold">Selected Options</h6>
          <div class="col-12">
            <MilesColourAndExtrasAdditionalOptions
              searchFieldTitle="Description"
              inputFieldTitle="Dealer Price (excl GST)"
              searchFieldLabel="Option Name"
              inputFieldLabel="0.00"
              v-model="optionalEquipment"
              :options="milesOptions"
              :onUpdateOptions="handleUpdateMilesOptions"
              filterByColumn="description"
              sortByColumn="description"
              :isLoading="$apollo.queries.options.loading"
              :isDisabled="isDisabled"
            />
          </div>
        </div>

        <QSeparator class="q-my-md" />
        <div class="row q-mb-lg q-col-gutter-md" v-if="!this.pricingConfiguration.isItemisedPricingEnabled">
          <div class="col-12 q-mb-sm">
            <p class="q-mb-md text-weight-bold text-gery-8">Vehicle Price*</p>
            <p class="q-mb-md text-grey-7" style="font-size: 0.75rem">
              Please select one of the following options below to calculate the lease quote.
            </p>
          </div>
          <div v-if="this.pricingConfiguration.isDriveawayPriceEnabled" class="col-12 col-md-6 q-mr-sm q-mb-sm row">
            <QRadio
              :class="`col-12 ${$v.driveAwayOptions.$error && 'text-red'}`"
              val="DRIVEAWAYCOST"
              color="secondary"
              v-model="$v.driveAwayOptions.$model"
              :disable="isDisabled"
            >
              Dealer Driveaway Price
              <QIcon color="grey-7" class="q-ml-sm" :name="informationIcon" size="xs">
                <QTooltip :offset="[0, 10]" max-width="280px" anchor="bottom right" self="top left"
                  >If you have a Dealer quote please enter the total Driveaway Price including discounts, fees, on roads & GST. This is the
                  total amount payable to the Dealer.</QTooltip
                >
              </QIcon>
            </QRadio>
            <QInput
              v-if="$v.driveAwayOptions.$model === 'DRIVEAWAYCOST'"
              class="col-12 q-pb-none"
              v-model="$v.onRoadCost.$model"
              input-class="hide-input-arrows"
              filled
              color="secondary"
              dense
              clearable
              type="number"
              step=".01"
              prefix="$"
              :disable="$v.driveAwayOptions.$model !== 'DRIVEAWAYCOST' || isDisabled"
              :readonly="$v.driveAwayOptions.$model !== 'DRIVEAWAYCOST'"
              :error="$v.onRoadCost.$error"
              :rules="[() => $v.onRoadCost.isValidPrice || 'Luxury Vehicle – contact Channel for quote']"
              :loading="$apollo.queries.accountDetail.loading"
            />
          </div>
          <div class="col-12 col-md-6 q-mr-sm q-mb-sm row">
            <QRadio
              v-if="pricingConfiguration.isRRPEnabled"
              :class="`col-12 ${$v.driveAwayOptions.$error && 'text-red'}`"
              color="secondary"
              val="RRP"
              v-model="$v.driveAwayOptions.$model"
              :error="$v.driveAwayOptions.$error"
              :disable="isDisabled"
            >
              Estimated Driveaway Price
              <QIcon color="grey-7" class="q-ml-sm" :name="informationIcon" size="xs">
                <QTooltip :offset="[0, 10]" max-width="280px" anchor="bottom right" self="top left"
                  >The estimated Driveaway Price uses the RRP of the vehicle & does not include additional vehicle accessories. It can be
                  used if you don't have a Dealer quote & would like to quickly calculate a lease quote estimate.</QTooltip
                >
              </QIcon>
            </QRadio>
            <QInput
              class="col-12"
              :value="driveAwayRRP.toFixed(2)"
              input-class="hide-input-arrows"
              filled
              color="secondary"
              dense
              clearable
              type="number"
              step=".01"
              prefix="$"
              readonly
              disable
            />
          </div>
        </div>
      </div>
      <MilesQuoteNavigationActions back-to="quoteLeaseDetails">
        <template slot="next" v-if="!$apollo.queries.vehicleOptions.loading">
          <QBtn
            type="submit"
            :label="isDisabled ? 'Next' : 'Save and Next'"
            :icon-right="this.nextIcon"
            color="secondary"
            :loading="saving"
            :disable="saving"
            :ripple="false"
          />
        </template>
      </MilesQuoteNavigationActions>
    </QForm>
  </div>
</template>

<script>
import { QSeparator, QForm, QBtn, Icons, QSpinner, QRadio, QInput, QIcon, QTooltip } from 'eclipx.ui';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import { required, requiredIf, decimal } from 'vuelidate/lib/validators';
import MilesQuoteNavigationActions from '../MilesQuoteNavigationActions';
import FormError from '@/components/FormError/FormError';
import ColourAndExtrasExteriorColour from '@/components/ColourAndExtras/ColourAndExtrasExteriorColour';
import ColourAndExtrasInteriorColour from '@/components/ColourAndExtras/ColourAndExtrasInteriorColour';
import MilesColourAndExtrasAdditionalOptions from './MilesColourAndExtrasAdditionalOptions.vue';
import QueryVehicleOptions from '@/graphql/queries/QueryVehicleOptions';
import InformationCaption from '@/components/InformationCaption/InformationCaption';
import { GLOBAL_ERROR } from '@/components/GlobalError/GlobalError';
import LoadingVehicle from '@/components/LoadingVehicle/LoadingVehicle';
import QueryAccount from '@/graphql/queries/QueryAccount';
import QueryOptions from '@/graphql/queries/QueryOptions';
import MutateQuote from '@/graphql/mutations/MutateQuote';
import UpdateMilesQuote from '@/graphql/mutations/UpdateMilesQuote';
import { isMilesAU } from '@/utils/is-miles';
import validateOptions from '@/graphql/mutations/ValidateOptions';

const OPTION_TYPES = {
  E: 'exteriorColours',
  I: 'interiorColours',
  O: 'optionalEquipment'
};

const DATA_TYPES = {
  E: 'exteriorColour',
  I: 'interiorColour',
  O: 'optionalEquipment'
};

export default {
  name: 'MilesColoursAndExtras',
  data() {
    return {
      selectedValue: [],
      saving: false,
      validationErrors: {},
      // Default value pre apollo query result
      vehicleOptions: {
        exteriorColours: [],
        interiorColours: [],
        optionalEquipment: [],
        includedEquipment: []
      },
      exteriorColour: null,
      interiorColour: null,
      optionalEquipment: [],
      adhocEquipment: [],
      onRoadCost: null,
      driveAwayOptions: null,
      milesOptions: [],
      milesAccessories: [],
      driveAwayRRP: 0
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  apollo: {
    options: {
      query: QueryOptions,
      variables: function () {
        return {
          typeId: this.erpquote.variant.id.toString()
        };
      },
      result({ data }) {
        this.milesOptions = [...data.options[0]?.MWSOptions?.MWSOption, ...this.optionalEquipment] || [];
        this.milesAccessories = [...data.options[0]?.MWSAccessoires?.MWSOption, ...this.adhocEquipment] || [];
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.vehicleOptions.refresh();
          },
          message
        });
      }
    },
    vehicleOptions: {
      query: QueryVehicleOptions,
      variables: function () {
        return {
          variantId: this.erpquote.variant.id.toString(),
          templateId: this.erpquote.product.id.toString(),
          quoteDetail: this.erpquote.quoteRef
        };
      },
      skip() {
        return !this.$store.getters['quote/quoteLeaseValid'];
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.vehicleOptions.refresh();
          },
          message
        });
      }
    },
    accountDetail: {
      query: QueryAccount,
      variables: function () {
        return { params: this.selected ? this.selected.id : this.user.organisation.id };
      },
      error: function ({ graphQLErrors }) {
        const errMessage = graphQLErrors.map((err) => {
          return err.message;
        });
        /*Extract error message */
        let message = errMessage.join().replace(/Error:|{}/gi, '');
        if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
          message = message
            .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
            .replace(/message|:|"/gi, '')
            .trim();
        }
        if (message && message.trim().endsWith('.')) {
          message = message.slice(0, -1);
        }
        if (/:|{|}|"|\?|%/.test(message)) {
          message = '';
        }
        this.$euiNotifications.emit({
          notification: GLOBAL_ERROR,
          action: () => {
            return this.$apollo.queries.accountDetail.refresh();
          },
          message
        });
      }
    }
  },
  validations: {
    exteriorColour: { required:requiredIf((component) => component.isMilesAU) } ,
    interiorColour: { required:requiredIf((component) => component.isMilesAU) } ,
    optionalEquipment: {},
    quoteRefDetail: {},
    onRoadCost: {
      required: requiredIf((component) => component.driveAwayOptions === 'DRIVEAWAYCOST'),
      decimal,
      isValidFormat(value) {
        const regex = /^[0-9]{3,9}(\.[0-9]{1,2})?$/;

        if (this.driveAwayOptions !== 'DRIVEAWAYCOST') {
          return true;
        }

        return regex.test(value);
      },
      isValidPrice(value) {
        try {
          const price = parseFloat(value);
          const maxPrice = process.env.VUE_APP_MAX_VEHICLE_PRICE || 70000;
          if (price > maxPrice)
            // if price is over this means luxury vehicle
            return false;
        } catch {}
        return true;
      }
    },
    driveAwayOptions: { required: requiredIf((component) => !component.pricingConfiguration?.isItemisedPricingEnabled) }
  },
  computed: {
    ...mapState({
      erpquote: (state) => state.quote.quote.erpquote,
      selected: (state) => state.org.selectedOrg,
      user: (state) => state.auth.user,
      milesQuoteRef: (state) => state.quote.quote.erpquote?.quoteRef?.milesQuoteRef,
      dbQuoteId: (state) => state.quote.quote.dbquote?.id,
      dbQuote: (state) => state.quote.quote.dbQuoteId,
      erpQuoteRef: (state) => state.quote.quote.erpquote.erpQuoteRef
    }),
    ...mapGetters({
      hasRequestedPricing: 'quote/hasRequestedPricing',
      isExpired: 'quote/isExpired',
      saveQuoteFields: 'quote/saveQuoteFields',
      pricingConfiguration: 'orgProfile/pricingConfiguration'
    }),
    isMilesAU() {
      const erpCode = this.selected?.erps[0]?.code || this.user.organisation.erps[0].code;
      return isMilesAU(erpCode);
    },
    exteriorColorLabel: function () {
      return this.isMilesAU ? 'Exterior Colours*' : 'Exterior Colours (optional)';
    },
    interiorColorLabel: function () {
      return this.isMilesAU ? 'Interior Colours*' : 'Interior Colours (optional)';
    },
    isDisabled: function () {
      return this.hasRequestedPricing || this.isExpired;
    },
    selectedExteriorColour: function () {
      return this.findVehicleOption(this.exteriorColour, 'E');
    },
    selectedInteriorColour: function () {
      return this.findVehicleOption(this.interiorColour, 'I');
    },
    selectedOptionalEquipment: function () {
      return this.optionalEquipment.map((selectedOption) => this.findVehicleOption(selectedOption));
    },
    allRequired: function () {
      return {
        ...this.getConditionValues(this.selectedExteriorColour.requires),
        ...this.getConditionValues(this.selectedInteriorColour.requires)
      };
    },
    allPrecluded: function () {
      return {
        ...this.getConditionValues(this.selectedExteriorColour.precludes),
        ...this.getConditionValues(this.selectedInteriorColour.precludes),
        ...this.optionalEquipment.reduce((accumulator, currentValue) => {
          return {
            ...accumulator,
            ...this.getConditionValues(currentValue.precludes)
          };
        }, {})
      };
    },
    requiresOptionalExtra: function () {
      return Boolean(Object.keys(this.allRequired).find((option) => option.charAt(0).toLocaleLowerCase() === 'o'));
    },
    features() {
      return this.$store.getters['auth/feature'];
    },
    hasOwnDealerFeature() {
      return this.features.indexOf('DB::OwnDealer') > -1;
    },
    isDealer() {
      if (this.accountDetail?.partnerType === 'Dealer') {
        return true;
      }
      return false;
    }
  },
  watch: {
    driveAwayOptions: function (value) {
      if (value === 'DEALERPRICING') {
        this.onRoadCost = null;
        this.setDbQuoteFields({ is_request_pricing: true });
      } else {
        this.setDbQuoteFields({ is_request_pricing: false });
      }
    },
    // If the quote has updated and removed colours and extras from the summary card, update all page input values
    quote: function () {
      this.bindQuoteToInputs();
      this.$v.$touch();
    },
    allRequired: function (values, oldValues) {
      if (!values) return;
      // Remove colours / options that were required but are now not required
      Object.keys(oldValues).forEach((key) => {
        if (!values[key]) {
          this.removeSelection(key);
        }
      });
      // Ensure all required colours / options are added
      Object.keys(values).forEach((key) => this.addSelection(key));
    },
    allPrecluded: function (values) {
      if (!values) return;
      Object.keys(values).forEach((key) => this.removeSelection(key));
    }
  },
  methods: {
    ...mapMutations({ setDbQuoteFields: 'quote/setDbQuoteFields' }),
    ...mapActions({
      validateQuoteOptions: 'quote/validateQuoteOptions',
      saveQuoteColourAndExtras: 'quote/saveColourAndExtras',
      saveInitialQuoteDetails: 'quote/saveInitialQuoteDetails',
      saveItemisedPricing: 'quote/saveItemisedPricing'
    }),
    // Returns a vehicle option with an associated ID
    findVehicleOption(id, optType) {
      if (!id) {
        return {};
      }

      const idChar0 = id.charAt(0).toUpperCase();

      let optResult = null;
      if (/^[IEO]/.test(idChar0)) {
        optResult = this.vehicleOptions[OPTION_TYPES[idChar0]]?.find((option) => option.id === id) || {};
      } else {
        optResult = this.vehicleOptions[OPTION_TYPES[optType]]?.find((option) => option.code === id) || {};
      }
      return optResult;
    },
    getConditionValues(obj = {}) {
      let conditions = {};
      for (let key in obj) {
        // Ignore or conditions
        if (key === 'OR') {
          continue;
        }
        // Add all within an AND (excluding nested or conditions)
        if (key === 'AND') {
          conditions = obj[key].reduce((accumulator, currentValue) => {
            if (typeof currentValue === 'string') {
              accumulator[currentValue] = true;
            }
            return accumulator;
          }, conditions);
        }
      }
      return conditions;
    },
    addSelection(id) {
      let type = id.charAt(0).toUpperCase();
      switch (OPTION_TYPES[type]) {
        case OPTION_TYPES.E:
        case OPTION_TYPES.I:
          if (this[DATA_TYPES[type]] !== id) {
            this[DATA_TYPES[type]] = id;
          }
          break;
        case OPTION_TYPES.O:
          if (!this[DATA_TYPES[type]].find((option) => option === id)) {
            this[DATA_TYPES[type]].unshift(id);
          }
          break;
      }
    },
    removeSelection(id) {
      let type = id.charAt(0).toUpperCase();
      switch (OPTION_TYPES[type]) {
        case OPTION_TYPES.E:
        case OPTION_TYPES.I:
          if (this[DATA_TYPES[type]] === id) {
            this[DATA_TYPES[type]] = null;
          }
          break;
        case OPTION_TYPES.O:
          let index = this[DATA_TYPES[type]].findIndex((option) => option === id);
          if (index > -1) {
            this[DATA_TYPES[type]].splice(index, 1);
          }
          break;
      }
    },
    bindQuoteToInputs() {
      this.exteriorColour = this.erpquote.exteriorColour?.id;
      this.interiorColour = this.erpquote.interiorColour?.id;
      this.optionalEquipment = this.erpquote?.optionalEquipment || [];
      this.adhocEquipment = this.erpquote?.adhocEquipment || [];
      this.onRoadCost = this.erpquote?.price?.onRoadCost;
      this.driveAwayRRP = parseFloat(this.erpquote?.variant?.listPriceGross || 0);
      this.isRRPSelected = this.erpquote?.price?.isRRPSelected || this.onRoadCost == this.driveAwayRRP;
      this.milesAccessories = [...this.milesAccessories, ...this.adhocEquipment];
      this.milesOptions = [...this.milesOptions, ...this.optionalEquipment];
      this.driveAwayOptions = this.isRRPSelected ? 'RRP' : this.onRoadCost ? 'DRIVEAWAYCOST' : null;
    },
    goToNext() {
      this.$router.push({
        name: 'quoteCustomerDetails'
        // params: { quoteId: this.$route.params.quoteId }
      });
    },
    handleUpdateAccessories(value) {
      this.milesAccessories = [...this.milesAccessories, value];
    },
    handleUpdateMilesOptions(value) {
      this.milesOptions = [...this.milesOptions, value];
    },
    saveColourAndExtras: async function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      if (!this.isDisabled) {
        let onRoadCost = null;
        let isRRPSelected = false;

        if (!this.pricingConfiguration.isItemisedPricingEnabled) {
          switch (this.driveAwayOptions) {
            case 'DRIVEAWAYCOST':
              onRoadCost = this.onRoadCost;
              break;
            case 'RRP':
              onRoadCost = this.driveAwayRRP;
              isRRPSelected = true;
              break;
            default:
              onRoadCost = null;
              break;
          }

          onRoadCost = onRoadCost && parseFloat(onRoadCost);
        }

        let updatedValues = {
          exteriorColour: this.selectedExteriorColour,
          interiorColour: this.selectedInteriorColour,
          optionalEquipment: this.optionalEquipment,
          adhocEquipment: this.adhocEquipment,
          erpQuoteRef: this.erpQuoteRef ? this.erpQuoteRef : this.vehicleOptions.quoteRef ? this.vehicleOptions.quoteRef.quoteId : null, // For miles
          quoteRef: this.vehicleOptions.quoteRef, // for miles
          price: {
            ...this.erpquote.price,
            onRoadCost,
            isRRPSelected
          }
        };

        if (!this.milesQuoteRef) {
          this.saving = true;
          await this.validateQuoteOptions(updatedValues.quoteRef.vehicle.id, [
            this.selectedExteriorColour?.catalogId,
            this.selectedInteriorColour?.catalogId,
            [...updatedValues.optionalEquipment.map((option) => option.catalogOptionType)]
          ])
            .then(async (result) => {
              const {
                data: {
                  validateOptions: { success, errors }
                }
              } = result;
              if (success === false) {
                this.validationErrors = { ...errors };
                this.saving = false;
                return;
              }
              this.saveQuoteColourAndExtras({
                ...this.erpquote,
                ...updatedValues,
                ...(this.dbquote?.id && {
                  dbQuoteId: this.dbquote?.id,
                  dbStatus: this.dbquote?.quote_status_code
                })
              });

              await this.saveOrderDetails();
            })
            .catch((error) => {
              let gqlError = error.graphQLErrors[0];
              this.error = gqlError ? gqlError.message : 'Error';
            });
        } else if (this.milesQuoteRef && this.dbQuoteId) {
          this.saving = true;
          await this.validateQuoteOptions(updatedValues.quoteRef.vehicle.id, [
            this.selectedExteriorColour?.catalogId,
            this.selectedInteriorColour?.catalogId,
            [...updatedValues.optionalEquipment.map((option) => option.catalogOptionType)]
          ])
            .then(async (result) => {
              const {
                data: {
                  validateOptions: { success, errors }
                }
              } = result;
              if (success === false) {
                this.validationErrors = { ...errors };
                this.saving = false;
                return;
              }
              return await this.$apollo
                .mutate({
                  mutation: UpdateMilesQuote,
                  variables: {
                    params: {
                      dbQuoteId: this.dbQuoteId,
                      milesQuoteRef: this.milesQuoteRef,
                      erpQuoteData: {
                        ...this.erpquote,
                        ...updatedValues
                      }
                    }
                  },
                  update: (store, response) => {
                    if (response.data.updateMilesQuote) {
                      const { dbQuoteId, erpQuoteRef, erpStatus, dbStatus, inclusions, exclusions } =
                        response.data.updateMilesQuote.response;
                      this.saveQuoteColourAndExtras({
                        ...this.erpquote,
                        dbStatus,
                        dbQuoteId,
                        erpQuoteRef,
                        erpStatus,
                        inclusions,
                        exclusions,
                        ...updatedValues
                      });

                      this.goToNext();
                    }
                  }
                })
                .catch(() => {
                  this.$euiNotifications.emit({
                    notification: GLOBAL_ERROR,
                    action: () => {
                      return this.saveQuoteLeaseDetails();
                    }
                  });
                })
                .finally(() => {
                  this.saving = false;
                });
            })
            .catch((error) => {
              let gqlError = error.graphQLErrors[0];
              this.error = gqlError ? gqlError.message : 'Error';
            });
        } else {
          this.saveQuoteColourAndExtras({
            ...this.erpquote,
            ...updatedValues
          });
          this.goToNext();
        }
      } else {
        this.goToNext();
      }
    },
    validateQuoteOptions: async function (vehicleId, options) {
      options = options.filter((option) => option !== null && option !== undefined && option !== '');
      // Get the first optional equipment ID dynamically
      const firstEquipmentId = this.optionalEquipment[0]?.id;
      if (firstEquipmentId && !options.includes(firstEquipmentId)) {
        options.push(firstEquipmentId);
      }
      return this.$apollo.mutate({
        mutation: validateOptions,
        variables: {
          params: {
            vehicleId: vehicleId,
            options: options.join(',')
          }
        }
      });
    },
    saveOrderDetails: async function () {
      window.scrollTo(0, 0);
      if (this.isExpired) {
        this.goToNext();
      }

      let leaseUpdatedValues = {
        leaseTerm: this.erpquote.leaseTerm,
        kmsPerYear: this.erpquote.kmsPerYear,
        availableOptions: {
          ...this.erpquote.availableOptions,
          ...(this.erpquote.availableOptions?.tyres && {
            tyres: this.erpquote.availableOptions.tyres === 'NONE' ? 0 : this.erpquote.availableOptions.tyres
          })
        },
        leaseStart: this.erpquote.leaseStart,
        leaseEnd: this.erpquote.leaseEnd
      };

      let colourAndExtrasUpdatedValues = {
        exteriorColour: this.erpquote.exteriorColour,
        interiorColour: this.erpquote.interiorColour,
        optionalEquipment: this.erpquote.optionalEquipment,
        adhocEquipment: this.erpquote.adhocEquipment,
        price: this.erpquote.price
      };

      let updatedValues = {
        ...leaseUpdatedValues,
        ...colourAndExtrasUpdatedValues
      };

      this.saving = true;

      return await this.$apollo
        .mutate({
          mutation: MutateQuote,
          variables: {
            params: {
              ...this.saveQuoteFields,
              erpQuoteData: {
                ...this.erpquote,
                ...updatedValues,
                customer: {}
              }
            }
          },
          update: (store, response) => {
            if (response.data.saveQuote) {
              this.saveInitialQuoteDetails({
                ...this.erpquote,
                ...response.data.saveQuote.response
              });

              if (this.pricingConfiguration.isItemisedPricingEnabled) {
                this.saveItemisedPricing({
                  itemisedPricing: response.data.saveQuote.response.itemisedPricing,
                  availableOptions: {
                    ...this.erpquote.availableOptions
                  }
                });
              }

              this.goToNext();
            }
          }
        })
        .catch(({ graphQLErrors }) => {
          let errMessage = graphQLErrors.map((err) => {
            return err.message;
          });
          /*Extract error message */
          let message = errMessage.join().replace(/Error:|{}/gi, '');
          if (message && message.startsWith('{"extensions":') && message.includes('"message"') && message.endsWith('}}}}}')) {
            message = message
              .substring(message.indexOf('"message'), message.indexOf('"}}}}}'))
              .replace(/message|:|"/gi, '')
              .trim();
          }
          if (message && message.trim().endsWith('.')) {
            message = message.slice(0, -1);
          }
          if (/:|{|}|"|\?|%/.test(message)) {
            message = '';
          }
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.saveOrderDetails();
            },
            message
          });
          this.goToNext();
        })
        .finally(() => {
          this.saving = false;
        });
    }
  },
  created() {
    this.nextIcon = Icons.evaArrowForward;
    this.backIcon = Icons.evaArrowBack;
    this.informationIcon = Icons.evaInfoOutline;
    // If the prior page is invalid, go to the first incomplete page
    if (!this.$store.getters['quote/quoteLeaseValid']) {
      this.$router.replace({
        name: this.$store.getters['quote/firstIncompletePage'],
        params: { quoteId: this.$route.params.quoteId }
      });
    }

    this.bindQuoteToInputs();
  },
  components: {
    QIcon,
    QTooltip,
    QInput,
    QRadio,
    QSeparator,
    QForm,
    QBtn,
    MilesQuoteNavigationActions,
    MilesColourAndExtrasAdditionalOptions,
    FormError,
    QSpinner,
    ColourAndExtrasExteriorColour,
    ColourAndExtrasInteriorColour,
    InformationCaption,
    LoadingVehicle
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="sass">
.center-button
  margin-top: 2px

.q-tooltip--style
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.03)
  background: #fff !important
  color: #424242 !important;
  border: 1px solid rgba(0, 0, 0, 0.05)
  padding: 18px
</style>
