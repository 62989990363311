<template>
  <div class="bg-white q-pa-lg" v-if="$store.getters['auth/hasFeature']('DB::ManageOrganisations')">
    <SiteConfigTabs />
    <div class="bg-white q-pa-lg">
      <div class="row justify-end col-grow items-center q-gutter-x-md">
        <QSelect
          v-model="organisationSearch"
          popup-content-class="limit-q-menu-height"
          color="secondary"
          filled
          dense
          use-input
          fill-input
          hide-selected
          hide-bottom-space
          clearable
          :options="filterSearchedOrganisations"
          :option-label="getLabel"
          @input-value="inputOrganisationValue"
          @option-value="optionOrganisationValue"
        />
        <QBtn
          type="input"
          label="Search"
          color="secondary"
          unelevated
          :ripple="false"
          :loading="$apollo.queries.getOrgByAppCode.loading"
          @click.prevent="search"
        />
      </div>

      <QTable
        flat
        :loading="$apollo.queries.getOrgByAppCode.loading"
        :columns="organisationColumns"
        :data="allOrganisations"
        :pagination.sync="pagination"
        @row-click="this.editOrganisation"
        @request="onRequest"
      >
        <template v-slot:body-cell-pricingConfiguration="props">
          <QTd :props="props" style="display: flex">
            <div v-for="(item, index) in getPricingConfigDetails(props.row.pricingConfiguration)" :key="item.status" @click.stop>
              <QBtn
                :class="
                  getPricingConfigDetails(props.row.pricingConfiguration).length &&
                  index != getPricingConfigDetails(props.row.pricingConfiguration).length &&
                  'q-mr-sm'
                "
                dense
                color="grey-8"
                :icon="item.icon"
                outline
                style="border-radius: 22.5px; width: 45px"
              >
                <QTooltip :offset="[-3, -3]" anchor="bottom right" self="top left"> {{ item.label }} </QTooltip>
              </QBtn>
            </div>
          </QTd>
        </template>
        <template v-slot:body-cell-calculatorEnabled="props">
          <QTd :props="props">
            {{ getCalculatorDetails(props.row.pricingConfiguration) }}
          </QTd>
        </template>
      </QTable>
    </div>
  </div>
</template>

<script>
import { QTable, QSelect, QBtn, Icons, QTooltip, QTd } from 'eclipx.ui';
import { mapGetters } from 'vuex';
import { calculator as calculatorIcon } from '@/icons/customIcons';
import { GLOBAL_ERROR } from '../../components/GlobalError/GlobalError';
import QueryOrganisations from '../../graphql/queries/QueryOrganisations';
import organisationsTableColumns from './organisationsTableColumns';
import SiteConfigTabs from '../Admins/SiteConfigTabs';

export default {
  name: 'Organisations',
  data() {
    return {
      getOrgByAppCode: { rows: [] },
      searchOrganisations: { rows: [] },
      sortBy: 'name',
      descending: true,
      page: 1,
      rowsPerPage: 20,
      organisationSearch: null,
      tableSearchFilter: null
    };
  },
  computed: {
    ...mapGetters({
      organisations: 'org/org',
      isUserNZ: 'auth/isUserNZ'
    }),
    organisationColumns: function () {
      return organisationsTableColumns.map((column) => {
        if (this.isUserNZ && column.name == 'abn') {
          return {
            ...column,
            label: 'NZBN'
          };
        }

        return column;
      });
    },
    filterSearchedOrganisations: function () {
      const rows = this.organisations.filter((el) => el != null);

      if (!this.organisationSearch) {
        return rows;
      }

      const sortData = (data) =>
        data.sort((orgA, orgB) => {
          const nameA = orgA.name.toUpperCase();
          const nameB = orgB.name.toUpperCase();

          return nameA.localeCompare(nameB);
        });

      const filterName = this.searchFilter().toUpperCase();

      const filterByNearestTerm = sortData(
        rows.filter(({ name, abn }) => {
          const organisationName = this.getLabel({ name, abn }).toUpperCase();

          return organisationName.slice(0, filterName.length) === filterName;
        })
      );

      const filterBySimilarLetterPattern = sortData(
        rows.filter(({ name, abn }) => {
          const organisationName = this.getLabel({ name, abn }).toUpperCase();

          return organisationName.indexOf(filterName) > -1;
        })
      );

      return [...filterByNearestTerm, ...filterBySimilarLetterPattern].reduce((previousValue, current) => {
        const existingIndex = previousValue.findIndex((option) => option.abn === current.abn && option.name === current.name);

        if (existingIndex === -1) {
          previousValue.push(current);
        }

        return previousValue;
      }, []);
    },
    allOrganisations: function () {
      const rows = this.getOrgByAppCode.rows;
      return rows.filter((el) => el != null);
    },
    pagination: {
      get: function () {
        return {
          sortBy: this.sortBy,
          descending: this.descending,
          page: this.page,
          rowsPerPage: this.rowsPerPage,
          rowsNumber: this.getOrgByAppCode.count
        };
      },
      set: function (newValue) {}
    }
  },
  apollo: {
    getOrgByAppCode: {
      query: QueryOrganisations,
      fetchPolicy: 'network-only',
      variables() {
        return {
          params: {
            page: this.page - 1, // Quasar table is 1 based, the api is 0 based
            size: this.rowsPerPage,
            sortBy: this.sortBy,
            sortType: this.descending ? '-' : ' ',
            appCode: 'DB',
            searchFilter: this.tableSearchFilter || ''
          },
          hasOrgProfile: true
        };
      },
      error: function (error) {
        if (error) {
          this.$euiNotifications.emit({
            notification: GLOBAL_ERROR,
            action: () => {
              return this.$apollo.queries.getOrgByAppCode.refresh();
            }
          });
        }
      }
    }
  },
  mounted() {
    this.listIcon = Icons.evaList;
    this.priceTagsIcon = Icons.evaPricetagsOutline;
    this.onRequest({
      pagination: this.pagination,
      filter: undefined
    });
  },
  methods: {
    getLabel: (value) => {
      if (typeof value === 'object') {
        let prefix = '';
        try {
          prefix = value?.erps[0].code === 'MILESNZ' ? '(NZBN)' : '(ABN)';
        } catch (e) {}

        return !!value.abn ? `${value.name} ${prefix}: ${value.abn})` : value.name;
      }

      return value;
    },
    editOrganisation: function (event, row) {
      this.$router.push({
        name: 'organisationEdit',
        params: { orgId: row.id }
      });
    },
    onRequest: function (props) {
      const { page, rowsPerPage, sortBy, descending } = props.pagination;
      const filter = props.filter;

      setTimeout(() => {
        this.page = page;
        this.rowsPerPage = rowsPerPage;
        this.sortBy = sortBy;
        this.descending = descending;
      }, 500);
    },
    inputOrganisationValue: function (value) {
      this.organisationSearch = value;
    },
    optionOrganisationValue: function (value) {
      return value.name;
    },
    search: function () {
      this.tableSearchFilter = this.searchFilter();
    },
    searchFilter: function () {
      const value = this.organisationSearch;
      if (typeof value === 'object') {
        return value.name;
      }

      return value;
    },
    getPricingConfigDetails: function (value) {
      const pricingConfiguration = [
        { status: 'isItemisedPricingEnabled', label: 'Itemised Pricing', icon: this.listIcon },
        { status: 'isRRPEnabled', label: 'Estimated Driveaway Price', icon: calculatorIcon },
        { status: 'isDriveawayPriceEnabled', label: 'Dealer Driveaway Price', icon: this.priceTagsIcon }
      ];

      return pricingConfiguration.filter(({ status }) => value[status]);
    },
    getCalculatorDetails: function (value) {
      return value?.calculator?.isCalculatorEnabled ? 'ACTIVE' : 'INACTIVE';
    }
  },
  components: {
    QTable,
    QSelect,
    QBtn,
    SiteConfigTabs,
    QTooltip,
    QTd
  }
};
</script>
