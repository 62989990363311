export default {
  namespaced: true,
  state: {
    auth0: {},
    dragonball: {},
    calculator: {},
    quote: {},
    scoreboard: {},
    email: {},
    whiteLabel: {},
    isPending: false,
    saving: false
  },
  getters: {
    auth0(state) {
      return state.auth0;
    },
    dragonball(state) {
      return state.dragonball;
    },
    calculator(state) {
      return state.calculator;
    },
    quote(state) {
      return state.quote;
    },
    scoreboard(state) {
      return state.scoreboard;
    },
    email(state) {
      return state.email;
    },
    isPending(state) {
      return state.isPending;
    },
    saving(state) {
      return state.saving;
    },
    whiteLabel(state) {
      return state.whiteLabel;
    }
  },
  mutations: {
    clearWhiteLabel(state) {
      state.auth0 = {};
      state.dragonball = {};
      state.quote = {};
      state.scoreboard = {};
      state.email = {};
      state.calculator = {};
    },
    setWhiteLabel(state, params) {
      state.auth0 = params.auth0;
      state.dragonball = params.dragonball;
      state.quote = params.quote || {};
      state.scoreboard = params.scoreboard;
      state.email = params.email;
      state.isPending = params.isPending;
      state.whiteLabel = params.whiteLabel;
      state.calculator = params.calculator || {};
    },
    updateValueStatus(state, params) {
      const { object, variable, value } = params;
      if (state[object]) state[object][variable] = value;
    },
    setSaving(state, saving) {
      state.saving = saving;
    },
    setIsPending(state, isPending) {
      state.isPending = isPending;
    },
    updateValueStatusEmail(state, params) {
      const { object, type, type2, variable, value } = params;
      if (state[object][type][type2] === undefined) {
        state[object][type][type2] = {
          [variable]: value
        };
      }
      if (state[object] && state[object][type] && state[object][type][type2]) state[object][type][type2][variable] = value;
    },
    updateEmailBannerImage(state, params) {
      const { object, type, field, value } = params;
      if (state[object] && state[object][type]) state[object][type][field] = value;
    }
  }
};
